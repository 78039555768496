<template>
    <div>
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                title="活动规则"
                z-index="99"
                class="header"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div class="Rules" >
            <p class="title">{{markList.title}}</p>
            <div v-html="markList.content"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Rules",
        data() {
            return {
                markList: [],
            }
        },
        created() {
            this.mark()
        },
        methods: {
            mark() {
                this.$axios.get('/illustration/index', {
                    params: {
                        mark: 'invite'
                    }
                })
                    .then(res => {
                        this.markList = res.data.data
                    })
            }
        },
    }
</script>

<style scoped>
    .title{
        margin-bottom: 40px;
        text-align: center;
    }
    .Rules {
        padding: 40px;
        text-align: justify;
        line-height: 40px;
        font-size: 26px;
        color: #606266;
        margin-bottom: 100px;
    }
</style>